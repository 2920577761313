import React from "react"
import PropTypes from "prop-types"
import theme from "theme"

const EmbeddedVideo = ({ asset: { _ref } }) => {
  return (
    <video
      src={videoUrl(_ref)}
      controls
      playsInline
      css={{
        display: "block",
        width: "100%",
        height: "auto",
        maxWidth: 540,
        margin: "30px auto",
        [theme.max(540)]: {
          margin: "30px calc(-1 * var(--gutters, 0))",
          width: "100vw",
          maxWidth: "100vw",
        },
      }}
    />
  )
}

export default EmbeddedVideo

EmbeddedVideo.propTypes = {
  asset: PropTypes.shape({
    _ref: PropTypes.string.isRequired,
  }).isRequired,
}

const pattern = /^file-([a-f\d]+)-(?:(?:\d+x\d+)-)?(\w+)$/

const videoUrl = id => {
  const [, assetId, format] = pattern.exec(id)

  return [
    "https://cdn.sanity.io",
    "files",
    process.env.SANITY_PROJECT_ID,
    process.env.SANITY_DATASET,
    `${assetId}.${format}`,
  ].join("/")
}
