import React from "react"
import theme from "theme"

const Blockquote = props => (
  <blockquote
    css={{
      display: "flex",
      margin: "64px auto",
      maxWidth: 790,
      padding: "24px 30px",
      background: theme.tanLight,
      fontStyle: "italic",
      fontSize: 32,
      lineHeight: 1.1875,
      fontWeight: 400,
      ":before": {
        content: "''",
        display: "block",
        flex: "0 0 2px",
        width: 2,
        background: theme.stampRed,
        marginRight: 24,
      },
      [theme.mobile]: {
        fontSize: 19,
        margin: "30px auto",
      },
      [theme.max(790)]: {
        margin: "30px calc(-1 * var(--gutters, 0))",
      },
    }}
    {...props}
  />
)

export default Blockquote
