import React from "react"
import PropTypes from "prop-types"
import PortableText from "react-portable-text"
import Blockquote from "components/Blockquote"
import EmbeddedImage from "components/EmbeddedImage"
import EmbeddedVideo from "components/EmbeddedVideo"
import AutoLink from "components/AutoLink"
import theme from "theme"

const ArticleText = ({
  serializers = {},
  childTypes = [],
  childStyles = {},
  ...rest
}) => (
  <PortableText
    {...rest}
    serializers={{
      intro: props => <p css={theme.t1} {...props} />,
      h2: props => (
        <h2
          css={{
            ...theme.h1,
            marginTop: 65,
            [theme.mobile]: { marginTop: 30 },
          }}
          {...props}
        />
      ),
      h3: props => (
        <h3
          css={{
            ...theme.h2,
            marginTop: 54,
            [theme.mobile]: { marginTop: 30 },
          }}
          {...props}
        />
      ),
      h4: props => (
        <h4
          css={{
            ...theme.h3,
            marginTop: 30,
            [theme.mobile]: { marginTop: 30 },
          }}
          {...props}
        />
      ),
      link: props => <AutoLink css={{ color: theme.stampRed }} {...props} />,
      blockquote: Blockquote,
      embeddedImage: EmbeddedImage,
      embeddedVideo: EmbeddedVideo,
      ...serializers,
    }}
    css={{
      [["p", "h2", "h3", "h4", "ul", "ol", ...(childTypes || [])]
        .map(el => `& > ${el}`)
        .join(", ")]: {
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: 540,
        ...childStyles,
      },
    }}
  />
)

export default ArticleText

ArticleText.propTypes = {
  content: PortableText.propTypes.content,
  serializers: PropTypes.object,
  childTypes: PropTypes.arrayOf(PropTypes.string),
  childStyles: PropTypes.object,
}
