import React from "react"
import PropTypes from "prop-types"
import SanityImage from "gatsby-plugin-sanity-image"
import imageProptypes from "lib/imageProptypes"
import theme from "theme"

const EmbeddedImage = ({ mode, image }) => (
  <SanityImage
    {...image}
    width={mode === "inline" ? 540 : 1440}
    alt=""
    options={{ __experimentalAspectRatio: true }}
    css={
      mode === "inline"
        ? {
            display: "block",
            width: "100%",
            height: "auto",
            maxWidth: 540,
            margin: "30px auto",
            [theme.max(540)]: {
              margin: "30px calc(-1 * var(--gutters, 0))",
              width: "100vw",
              maxWidth: "100vw",
            },
          }
        : {
            display: "block",
            width: "100vw",
            height: "auto",
            maxWidth: "100vw",
            margin: "60px calc(-1 * var(--gutters, 0))",
            [theme.mobile]: {
              marginTop: 30,
              marginBottom: 30,
            },
          }
    }
    sizes={mode === "inline" ? "(max-width: 540px) 100vw, 540px" : "100vw"}
  />
)

export default EmbeddedImage

EmbeddedImage.propTypes = {
  mode: PropTypes.oneOf(["inline", "full-width"]).isRequired,
  image: imageProptypes.isRequired,
}
